import classNames from 'classnames';
import type { IconName } from '../ui/Icon';
import { Icon } from '../ui/Icon';

export enum HexagonIconSize {
  Default = 'default',
  Small = 'small'
}

export enum HexacgonIconColor {
  Default = 'purple',
  Orange = 'orange',
  Red = 'red',
  Green = 'green'
}

type HexagonIconProps = {
  iconName?: IconName;
  title?: string;
  content?: string;
  size?: HexagonIconSize;
  className?: string;
  color?: HexacgonIconColor;
}

export const HexagonIcon = ( { className, title, iconName, content, size = HexagonIconSize.Default, color = HexacgonIconColor.Default }: HexagonIconProps ) => {

  const backgroundImages : Record<HexacgonIconColor, string> = {
    [HexacgonIconColor.Default]: 'https://cdn.rewarble.com/ui/hexagonicon/background.jpg',
    [HexacgonIconColor.Orange]: 'https://cdn.rewarble.com/ui/hexagonicon/background-orange.jpg',
    [HexacgonIconColor.Red]: 'https://cdn.rewarble.com/ui/hexagonicon/background-red.jpg',
    [HexacgonIconColor.Green]: 'https://cdn.rewarble.com/ui/hexagonicon/background-green.jpg'
  }

  return (
    <div className={ classNames( 
      'flex flex-col items-center -mt-4',
      className
    ) }>
      { iconName &&
        <div className={ 'relative' }>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={ size === HexagonIconSize.Small ? '72' : '104' }
            height="112"
            viewBox="0 0 104 112"
            fill="none">
            <g
              opacity="0.8"
              filter="url(#filter0_d_99_273)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.5398 20.6805C52.9682 19.7732 51.0319 19.7732 49.4603 20.6805L22.5389 36.2236C20.9673 37.1309 19.9992 38.8078 19.9992 40.6225V71.7086C19.9992 73.5233 20.9673 75.2002 22.5389 76.1076L49.4603 91.6506C51.0319 92.558 52.9681 92.558 54.5397 91.6506L81.4611 76.1076C83.0327 75.2002 84.0008 73.5233 84.0008 71.7086V40.6225C84.0008 38.8078 83.0327 37.1309 81.4611 36.2236L54.5398 20.6805Z"
                fill="url(#pattern0)"/>
            </g>
            <defs>
              <filter
                id="filter0_d_99_273"
                x="0.735023"
                y="0.735847"
                width="102.53"
                height="110.859"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood
                  floodOpacity="0"
                  result="BackgroundImageFix"/>
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="9.63208"/>
                { color === HexacgonIconColor.Default &&
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.345098 0 0 0 0 1 0 0 0 0 0.898039 0 0 0 0.74 0"/>
                }
                { color === HexacgonIconColor.Orange &&
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.36 0 0 0 0 0 0 0 0 0.74 0"/>
                }
                { color === HexacgonIconColor.Green &&
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.1 0 0 0 0.74 0"/>
                }
                { color === HexacgonIconColor.Red &&
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0.74 0"/>
                }
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_99_273"/>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_99_273"
                  result="shape"/>
              </filter>
              <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1">
                <use
                  xlinkHref="#image0_99_273"
                  transform="matrix(0.00073589 0 0 0.000615917 -0.212985 -0.103326)"/>
              </pattern>
              <image
                id="image0_99_273"
                width="2227"
                height="2065"
                xlinkHref={ backgroundImages[color] }/>
            </defs>
          </svg>
          <div 
            data-size={ size }
            className={ 'absolute inset-0 flex items-center justify-center text-[26px] data-[size="small"]:icon-size-5 icon-size-7' }>
            <Icon
              name={ iconName }
              className={ 'text-white/90' } />
          </div>
        </div>
      }
      <div
        data-size={ size }
        className={ 'flex flex-col gap-4 data-[size="small"]:gap-3' }>
        { title &&
          <span 
            data-size={ size }
            className={ 'font-onest text-center text-3xl data-[size="small"]:text-2xl font-semibold text-[#ABB0C7] drop-shadow-[0_4px_4px_rgba(25,14,61,0.30)]' }>
            { title }
          </span>
        }
        { content && 
          <p 
            className={ 'text-center text-base drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] font-normal text-[#ABB0C7]' }>
            { content }
          </p>
        }
      </div>
    </div>
  )
}